import React from 'react';
import {SC} from './styles/textContainerImageComponent';

interface ComponentProps {
  title: string;
  content: string;
  image?: string;
  cta?: string | null;
  direction?: string | null;
}

const TextContainerImageComponent: React.FC<ComponentProps> = ({ title, content, image, cta, direction = 'LEFT' }) => {
  return (
    <SC.TextContainerImageComponent hasImage={!!image}>
      <div className={'content-container'}>
        {direction == 'LEFT' && (
          <div className={'side image'}>
            <img loading="lazy" src={image} alt={'about-us'} />
          </div>
        )}
        <div className={'side content'}>
          <div className={'title'}>{title}</div>
          <div className={'description'} dangerouslySetInnerHTML={{ __html: content }} />
          {cta && (
            <div className={'action'}>
              <a href={'#contact'}>{cta}</a>
            </div>
          )}
        </div>
        {direction == 'RIGHT' && (
          <div className={'side image'}>
            <img loading="lazy" src={image} alt={'about-us'} />
          </div>
        )}
      </div>
    </SC.TextContainerImageComponent>
  );
};

export default TextContainerImageComponent;
